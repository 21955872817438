import React from 'react';
import Logo from './Logo';

const Navbar = () => {
  const handleLoginClick = () => {
    window.location.href = 'https://chat.jerusalemai.com';
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-black z-50 border-0">
      <div className="max-w-[1240px] mx-auto px-6 relative">
        <div className="flex items-center h-[64px] justify-between">
          <div className="absolute left-[-300px] flex-shrink-0">
            <Logo />
          </div>
          <div className="flex items-center space-x-5 ml-[490px]">
            <a href="/overview" className="text-white/70 hover:text-white transition-colors">Overview</a>
            <a href="/pricing" className="text-white/70 hover:text-white transition-colors">Pricing</a>
            <a href="/stories" className="text-white/70 hover:text-white transition-colors">Stories</a>
            <a href="/docs" className="text-white/70 hover:text-white transition-colors">Docs</a>
          </div>
          <div className="flex-shrink-0">
            <button 
              onClick={handleLoginClick}
              className="px-4 py-2 text-white bg-transparent border border-white/15 rounded-lg 
                hover:bg-white hover:text-black hover:border-white 
                active:bg-white/90 active:scale-95
                transition-all duration-200"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;