import React, { useState, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import '../App.css';
import Navbar from '../components/Navbar';

const posts = {
  all: [
    {
      category: 'Education',
      date: 'March 2024',
      title: 'Transforming Education in Jerusalem with AI',
      content: 'Discover how schools in Jerusalem are using JerusalemAI to enhance learning experiences, from virtual tours of historical sites to interactive language learning...'
    },
    {
      category: 'Business',
      date: 'March 2024',
      title: 'Local Businesses Thrive with AI Assistance',
      content: 'See how local merchants and tour guides are leveraging JerusalemAI to provide better services and reach global audiences...'
    },
    {
      category: 'Tourism',
      date: 'March 2024',
      title: 'AI-Powered Exploration of Jerusalem',
      content: 'Experience how tourists are discovering hidden gems and navigating the city with confidence using real-time AI assistance...'
    }
  ],
  education: [
    {
      category: 'Education',
      date: 'March 2024',
      title: 'AI in Religious Studies',
      content: 'How theological students are using JerusalemAI to deepen their understanding of sacred texts and historical contexts...'
    }
  ],
  business: [
    {
      category: 'Business',
      date: 'March 2024',
      title: 'Smart Tourism Solutions',
      content: 'Local hotels and tour operators are revolutionizing guest experiences with AI-powered recommendations and support...'
    }
  ],
  exploration: [
    {
      category: 'Exploration',
      date: 'March 2024',
      title: 'Virtual Tours and Real Adventures',
      content: 'How JerusalemAI combines virtual guidance with real-world exploration for an enhanced city experience...'
    }
  ]
};

const roadmapStages = [
  {
    date: 'Q3 2024',
    title: 'JerusalemAI Launch',
    content: 'Successfully launched JerusalemAI, introducing AI-powered city exploration, real-time translation, and local insights to enhance the Jerusalem experience.'
  },
  {
    date: 'Q4 2024',
    title: 'Feature Enhancements',
    content: 'Introducing advanced navigation capabilities, improved language support, and enhanced cultural context understanding.'
  },
  {
    date: 'Q1 2025',
    title: 'Platform Expansion',
    content: 'Expanding features to include AR experiences, deeper integration with local businesses, and advanced educational tools.'
  }
];

const App = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const filteredPosts = activeTab === 'roadmap' 
    ? [] 
    : posts[activeTab as keyof typeof posts].filter((post: { title: string; content: string }) =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        post.content.toLowerCase().includes(searchQuery.toLowerCase())
      );

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      if (window.scrollY > 50) {
        navbar?.classList.add('scrolled');
      } else {
        navbar?.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      <div className="pt-24 px-4 md:px-8 max-w-[1240px] mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="flex flex-wrap gap-4 mb-8">
            <button 
              className={`px-4 py-2 rounded-lg border border-white/15 transition-all duration-200
                ${activeTab === 'all' ? 'bg-white text-black' : 'text-white/70 hover:bg-white/10'}`}
              onClick={() => setActiveTab('all')}
            >
              All Stories
            </button>
            <button 
              className={`px-4 py-2 rounded-lg border border-white/15 transition-all duration-200
                ${activeTab === 'education' ? 'bg-white text-black' : 'text-white/70 hover:bg-white/10'}`}
              onClick={() => setActiveTab('education')}
            >
              Education
            </button>
            <button 
              className={`px-4 py-2 rounded-lg border border-white/15 transition-all duration-200
                ${activeTab === 'business' ? 'bg-white text-black' : 'text-white/70 hover:bg-white/10'}`}
              onClick={() => setActiveTab('business')}
            >
              Business
            </button>
            <button 
              className={`px-4 py-2 rounded-lg border border-white/15 transition-all duration-200
                ${activeTab === 'exploration' ? 'bg-white text-black' : 'text-white/70 hover:bg-white/10'}`}
              onClick={() => setActiveTab('exploration')}
            >
              Exploration
            </button>
            <button 
              className={`px-4 py-2 rounded-lg border border-white/15 transition-all duration-200
                ${activeTab === 'roadmap' ? 'bg-white text-black' : 'text-white/70 hover:bg-white/10'}`}
              onClick={() => setActiveTab('roadmap')}
            >
              Roadmap
            </button>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="text"
              placeholder="Search posts"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="px-4 py-2 bg-white/5 border border-white/15 rounded-lg text-white placeholder:text-white/30 
                focus:outline-none focus:border-white/30 transition-colors"
            />
            <i className="fas fa-rss text-gray-400"></i>
          </div>
        </div>
        {activeTab === 'roadmap' ? (
          <div className="max-w-3xl mx-auto">
            <h2 className="text-4xl font-medium text-white mb-8">Product Roadmap</h2>
            <div className="relative">
              {roadmapStages.map((stage, index) => (
                <div key={index} className="mb-12 relative">
                  <div className="absolute left-0 w-px h-full bg-white/15" />
                  <div className="relative pl-8">
                    <div className="absolute left-[-4px] top-2 w-2 h-2 rounded-full bg-white" />
                    <span className="text-white/50 text-sm">{stage.date}</span>
                    <h3 className="text-white text-xl font-medium mt-2 mb-3">{stage.title}</h3>
                    <p className="text-white/70">{stage.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {filteredPosts.map((post, index) => (
              <div 
                key={index} 
                className="relative overflow-hidden rounded-lg border border-white/15 bg-black/50 backdrop-blur-sm transition-all duration-200 hover:scale-[1.02]"
              >
                <div className="p-6">
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-white/70 text-sm px-3 py-1 bg-white/10 rounded-full">
                      {post.category}
                    </span>
                    <span className="text-white/50 text-sm">{post.date}</span>
                  </div>
                  <h2 className="text-white text-xl font-medium mb-3">{post.title}</h2>
                  <p className="text-white/70">{post.content}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {activeTab === 'all' && (
          <div className="mt-12">
            <h2 className="text-3xl font-medium text-white mb-6">Latest news</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {posts.all.map((post, index) => (
                <div 
                  key={index} 
                  className="relative overflow-hidden rounded-lg border border-white/15 bg-black/50 backdrop-blur-sm transition-all duration-200 hover:scale-[1.02]"
                >
                  <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                      <span className="text-white/70 text-sm px-3 py-1 bg-white/10 rounded-full">
                        {post.category}
                      </span>
                      <span className="text-white/50 text-sm">November 2024</span>
                    </div>
                    <h2 className="text-white text-xl font-medium mb-3">{post.title}</h2>
                    <p className="text-white/70">{post.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      
      {/* Add footer */}
      <footer className="mt-24 text-white flex flex-col items-center gap-2 pb-8">
        <p>© 2024 JerusalemAI. All rights reserved.</p>
        <p className="text-white/70 text-sm">
          Powered by{' '}
          <a 
            href="https://leapthelimit.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="hover:text-white transition-colors underline"
          >
            Leapthelimit
          </a>
        </p>
      </footer>
    </div>
  );
};

export default App;