import * as React from "react";

interface SVGComponentProps {
  [key: string]: any;
}

const SVGComponent: React.FC<SVGComponentProps> = (props) => (
  <div 
    onClick={() => window.location.href = 'https://jerusalemai.com'}
    className="cursor-pointer hover:opacity-80 transition-opacity"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="35"
      viewBox="0 0 900.000000 166.000000"
      preserveAspectRatio="xMidYMid meet"
      style={{ marginLeft: '345px' }}
      {...props}
    >
      <g
        transform="translate(0.000000,186.000000) scale(0.100000,-0.100000)"
        fill="#ffffff"
        stroke="none"
      >
        <path d="M782 1762 c-68 -7 -132 -68 -153 -147 -9 -33 -8 -50 7 -93 58 -173 295 -177 357 -5 22 60 21 78 -3 130 -40 90 -104 125 -208 115z" />
        <path d="M4890 1298 c-3 -186 1 -952 5 -959 4 -5 28 -9 56 -9 l49 0 0 490 0 490 -55 0 c-37 0 -55 -4 -55 -12z" />
        <path d="M7879 1233 c-9 -10 -33 -54 -54 -98 -20 -44 -71 -149 -111 -232 -41 -84 -107 -222 -148 -305 -103 -215 -116 -243 -116 -257 0 -8 17 -11 58 -9 l57 3 42 83 c23 46 47 90 54 98 10 12 53 14 251 12 l239 -3 14 -25 c8 -14 29 -56 48 -95 l34 -70 56 -3 c52 -3 57 -1 57 17 0 12 -13 47 -29 78 -28 54 -87 176 -283 583 -105 220 -114 235 -135 238 -11 2 -26 -5 -34 -15z m52 -243 c23 -44 129 -270 129 -276 0 -4 7 -15 16 -25 9 -10 13 -23 9 -28 -9 -16 -355 -15 -355 1 0 6 31 75 70 153 38 78 74 154 80 169 11 29 37 32 51 6z" />
        <path d="M8484 1236 c-3 -7 -4 -33 -2 -57 l3 -44 60 -5 60 -5 0 -340 0 -340 -60 -5 -60 -5 0 -50 0 -50 187 -3 188 -2 6 24 c3 13 4 37 2 52 -3 28 -6 29 -63 34 l-60 5 0 340 0 340 50 3 c27 2 55 8 62 14 16 13 17 80 1 96 -19 19 -367 17 -374 -2z" />
        <path d="M538 1208 c-3 -7 -2 -38 1 -68 l6 -55 170 -5 170 -5 3 -326 2 -326 -24 -55 c-56 -126 -183 -181 -336 -146 -98 22 -180 125 -180 225 0 38 -3 45 -26 53 -28 11 -84 13 -108 4 -31 -12 -13 -140 33 -232 32 -65 99 -130 169 -163 65 -32 71 -33 197 -33 109 -1 138 2 175 19 109 47 192 136 236 250 17 46 19 80 19 460 l0 410 -251 3 c-196 2 -253 0 -256 -10z" />
        <path d="M1452 990 c-120 -43 -190 -130 -210 -260 -12 -78 4 -184 36 -247 29 -56 106 -121 173 -145 88 -31 300 -13 353 31 10 9 16 29 16 57 0 46 -7 52 -42 33 -27 -15 -143 -39 -185 -39 -71 0 -126 23 -176 74 -40 40 -48 54 -45 80 l3 31 237 3 c275 3 255 -7 239 117 -14 105 -31 144 -87 199 -83 83 -196 107 -312 66z m215 -119 c39 -29 63 -72 66 -118 l2 -38 -189 -3 -188 -2 7 37 c13 70 58 122 124 144 46 15 145 4 178 -20z" />
        <path d="M2345 995 c-43 -23 -93 -73 -122 -122 -14 -24 -29 -43 -34 -43 -5 0 -9 30 -9 68 0 37 -4 72 -8 79 -10 16 -57 26 -87 19 l-25 -6 2 -328 3 -327 51 -3 c65 -4 64 -7 64 204 l0 162 44 59 c54 74 99 103 171 110 l56 6 -3 66 -3 66 -35 3 c-19 1 -48 -5 -65 -13z" />
        <path d="M3535 992 c-68 -23 -111 -56 -135 -102 -29 -58 -26 -118 9 -166 35 -49 59 -64 136 -88 33 -10 78 -24 100 -32 23 -8 47 -14 54 -14 7 0 26 -9 42 -21 37 -26 40 -76 7 -106 -26 -22 -91 -43 -136 -43 -51 0 -135 23 -174 47 -50 31 -58 29 -58 -12 0 -63 22 -91 92 -114 153 -52 338 -13 398 84 29 46 28 136 -2 178 -36 52 -58 65 -150 91 -147 41 -174 51 -195 70 -32 27 -30 76 3 107 24 23 32 24 137 23 86 0 120 -4 145 -18 18 -9 37 -14 42 -11 14 9 12 92 -2 102 -29 18 -147 42 -203 42 -33 0 -83 -8 -110 -17z" />
        <path d="M4202 990 c-88 -22 -112 -41 -112 -87 0 -44 20 -51 73 -27 62 28 239 27 284 -2 42 -27 67 -60 81 -110 21 -72 16 -77 -52 -45 -69 32 -164 43 -237 28 -85 -18 -120 -38 -165 -93 -37 -44 -38 -50 -38 -118 0 -57 4 -79 22 -108 42 -68 125 -108 224 -108 57 0 80 5 131 31 34 16 76 46 93 65 34 38 44 36 44 -10 0 -62 9 -76 49 -76 20 0 42 5 49 12 16 16 16 273 1 389 -6 46 -20 101 -31 122 -68 134 -224 185 -416 137z m250 -356 c87 -34 94 -60 36 -125 -86 -95 -227 -117 -305 -46 -48 43 -41 124 15 162 32 22 47 25 124 25 57 0 102 -6 130 -16z" />
        <path d="M5397 988 c-131 -47 -207 -164 -206 -318 1 -218 130 -350 342 -350 82 0 204 27 233 51 19 15 19 88 0 95 -8 3 -25 0 -37 -6 -28 -16 -143 -40 -186 -40 -66 0 -125 24 -169 67 -40 40 -64 96 -49 111 4 4 113 9 243 12 l237 5 3 63 c11 235 -191 387 -411 310z m203 -106 c47 -23 89 -87 90 -134 l0 -38 -190 0 c-209 0 -200 -3 -176 65 18 50 67 102 108 114 54 15 130 12 168 -7z" />
        <path d="M6252 989 c-23 -11 -63 -40 -87 -65 -48 -48 -52 -47 -57 21 -3 33 -7 41 -31 49 -19 7 -34 7 -45 0 -15 -10 -17 -41 -17 -335 l0 -324 50 0 50 0 5 230 c3 127 7 231 8 233 16 26 92 84 120 92 78 21 127 9 163 -42 23 -32 24 -41 29 -273 l5 -240 49 -3 c32 -2 52 1 57 10 5 7 9 111 9 231 l0 218 50 49 c40 39 59 50 95 56 88 13 134 -10 158 -80 6 -17 13 -132 16 -256 l6 -225 47 -3 c43 -3 48 -1 54 22 3 14 4 130 2 258 -3 214 -6 236 -25 273 -27 51 -68 90 -115 109 -85 36 -172 8 -255 -81 -46 -49 -53 -54 -59 -37 -15 41 -68 95 -111 114 -56 25 -115 24 -171 -1z" />
        <path d="M2613 994 c-10 -4 -13 -63 -13 -244 0 -205 2 -245 17 -277 48 -104 119 -153 224 -153 69 0 121 24 180 82 21 21 44 36 49 33 6 -3 10 -28 10 -56 l0 -49 45 0 44 0 3 316 c2 173 0 324 -4 335 -8 21 -56 26 -89 9 -18 -10 -19 -23 -19 -232 l0 -222 -48 -46 c-45 -43 -102 -70 -149 -70 -37 0 -91 29 -117 63 l-26 34 0 224 c0 123 -4 230 -9 237 -9 14 -77 25 -98 16z" />
      </g>
    </svg>
  </div>
);

export default SVGComponent;
