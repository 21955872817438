import React, { useState } from 'react';
import Logo from './Logo';

const MobileNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const handleLoginClick = () => {
    window.location.href = 'https://chat.jerusalemai.com';
  };

  return (
    <>
      <nav className="md:hidden fixed top-0 left-0 right-0 bg-black z-50 border-b border-white/10">
        <div className="max-w-[1240px] mx-auto relative">
          <div className="h-[64px] flex items-center justify-end px-4">
            {/* Left - Logo */}
            <div className="absolute left-[-330px] flex-shrink-0">
              <Logo />
            </div>

            {/* Right Side Controls */}
            <div className="flex items-center gap-2">
              <button 
                onClick={handleLoginClick}
                className="px-2 py-1.5 text-sm text-white bg-transparent border border-white/15 rounded-lg 
                  hover:bg-white hover:text-black hover:border-white 
                  active:bg-white/90 active:scale-95
                  transition-all duration-200"
              >
                Login
              </button>

              <button 
                onClick={() => setIsMenuOpen(true)}
                className="text-white/70 hover:text-white transition-colors p-1"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <div className="fixed inset-0 z-[60] bg-black/80 backdrop-blur-xl md:hidden">
          <div className="max-w-[1240px] mx-auto relative">
            <div className="h-[64px] flex items-center px-4">
              {/* Left - Logo */}
              <div className="absolute left-[-330px] flex-shrink-0">
                <Logo />
              </div>

              {/* Close Button */}
              <div className="flex items-center ml-auto">
                <button 
                  onClick={() => setIsMenuOpen(false)}
                  className="text-white/70 hover:text-white transition-colors p-1"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          
          <nav className="flex flex-col items-start px-8 mt-12 gap-6">
            <a href="/overview" className="text-white/70 hover:text-white text-2xl transition-colors" onClick={() => setIsMenuOpen(false)}>Overview</a>
            <a href="/pricing" className="text-white/70 hover:text-white text-2xl transition-colors" onClick={() => setIsMenuOpen(false)}>Pricing</a>
            <a href="/stories" className="text-white/70 hover:text-white text-2xl transition-colors" onClick={() => setIsMenuOpen(false)}>Stories</a>
            <a href="/docs" className="text-white/70 hover:text-white text-2xl transition-colors" onClick={() => setIsMenuOpen(false)}>Docs</a>
          </nav>
        </div>
      )}
    </>
  );
};

export default MobileNavbar;