import React from 'react';
import { FaLinkedin, FaInstagram, FaFacebook, FaDiscord, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  const socialLinks = [
    { icon: <FaLinkedin size={24} />, href: 'https://linkedin.com/company/jerusalem-ai' },
    { icon: <FaInstagram size={24} />, href: 'https://instagram.com/jerusalemai_official' },
    { icon: <FaFacebook size={24} />, href: 'https://www.facebook.com/people/Jerusalem-AI/61569343810401/' },
    { icon: <FaDiscord size={24} />, href: 'https://discord.gg/cQc4zFJT7Z' },
    { icon: <FaYoutube size={24} />, href: 'https://youtube.com/@jerusalemai' },
  ];

  return (
    <footer className="w-full bg-black/50 backdrop-blur-sm border-t border-white/10 mt-auto">
      <div className="max-w-[1240px] mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <h3 className="text-white text-lg font-bold">JerusalemAI</h3>
            <p className="text-white/70">Your intelligent companion for exploring Jerusalem</p>
          </div>
          
          <div className="space-y-4">
            <h3 className="text-white text-lg font-bold">Product</h3>
            <ul className="space-y-2">
              <li><a href="/overview" className="text-white/70 hover:text-white transition-colors">Overview</a></li>
              <li><a href="/pricing" className="text-white/70 hover:text-white transition-colors">Pricing</a></li>
              <li><a href="/docs" className="text-white/70 hover:text-white transition-colors">Documentation</a></li>
            </ul>
          </div>
          
          <div className="space-y-4">
            <h3 className="text-white text-lg font-bold">Company</h3>
            <ul className="space-y-2">
              <li><a href="/stories" className="text-white/70 hover:text-white transition-colors">Stories</a></li>
              <li><a href="/about" className="text-white/70 hover:text-white transition-colors">About Us</a></li>
              <li><a href="/contact" className="text-white/70 hover:text-white transition-colors">Contact</a></li>
            </ul>
          </div>
          
          <div className="space-y-4">
            <h3 className="text-white text-lg font-bold">Connect</h3>
            <div className="flex gap-4">
              {socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/70 hover:text-white transition-colors"
                >
                  {link.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
        
        <div className="mt-12 pt-8 border-t border-white/10 flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-white/70 text-sm">© 2024 JerusalemAI. All rights reserved.</p>
          <div className="flex gap-6">
            <a href="/privacy" className="text-white/70 hover:text-white text-sm transition-colors">Privacy Policy</a>
            <a href="/terms" className="text-white/70 hover:text-white text-sm transition-colors">Terms of Service</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;