import React from 'react';

function News() {
  return (
    <div>
      <h1>News</h1>
      <p>Latest updates and news about JLM AI.</p>
    </div>
  );
}

export default News;
