import React from 'react';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';
import questionsAnswers from '../assets/images/questions-answers.png';
import documentCreation from '../assets/images/document-creation.png';
import navigationAssistance from '../assets/images/navigation-assistance.png';
import webSearch from '../assets/images/web-search.png';
import Footer from '../components/Footer';

const FeatureSection = ({ title, description, image, isReversed = false, gradient = "from-purple-600/20 to-blue-600/20" }: {
  title: string;
  description: string;
  image: string;
  isReversed?: boolean;
  gradient?: string;
}) => (
  <div className={`relative rounded-2xl overflow-hidden mb-32 group ${gradient ? `bg-gradient-to-br ${gradient}` : ''}`}>
    <div className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} gap-8 items-center p-8 md:p-12`}>
      <div className="w-full md:w-1/2">
        <div className="relative aspect-[4/3] rounded-xl overflow-hidden shadow-2xl">
          <img 
            src={image} 
            alt={title} 
            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
        </div>
      </div>
      <div className="w-full md:w-1/2 text-center md:text-left">
        <h2 className="text-white text-3xl md:text-4xl mb-6 font-bold">{title}</h2>
        <p className="text-white/80 text-lg leading-relaxed">{description}</p>
      </div>
    </div>
  </div>
);

function Overview() {
  const [isMobileView, setIsMobileView] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="bg-black min-h-screen flex flex-col">
      {isMobileView ? <MobileNavbar /> : <Navbar />}
      
      <div className="max-w-[1240px] mx-auto px-4 pt-[120px] pb-20">
        <section className="text-center mb-32">
          <div className="inline-block mb-6 px-4 py-1.5 bg-white/10 rounded-full backdrop-blur-sm">
            <span className="text-white/80 text-sm">Powered by Advanced AI</span>
          </div>
          <h1 className="text-4xl md:text-6xl text-white mb-6 font-bold bg-gradient-to-r from-white via-white/90 to-white/80 bg-clip-text text-transparent">
            Discover Jerusalem Like Never Before
          </h1>
          <p className="text-white/70 text-xl max-w-3xl mx-auto leading-relaxed">
            Your intelligent companion for exploring, learning, and experiencing the city's rich history, culture, and modern life.
          </p>
        </section>

        <FeatureSection
          title="Interactive Q&A"
          description="Ask questions and receive instant answers about Jerusalem's history, culture, and local services. Our AI understands context and provides accurate, relevant information in multiple languages."
          image={questionsAnswers}
          gradient="from-blue-600/20 to-cyan-600/20"
        />

        <FeatureSection
          title="Document Creation"
          description="Create documents, essays, blogs, and more with AI assistance. Perfect for students, researchers, and professionals looking to enhance their writing about Jerusalem."
          image={documentCreation}
          isReversed
          gradient="from-purple-600/20 to-pink-600/20"
        />

        <FeatureSection
          title="Navigation Assistance"
          description="Navigate the city effortlessly with Google Maps integration. Find the best routes to historical sites, markets, cafes, and cultural attractions with local insights."
          image={navigationAssistance}
          isReversed
          gradient="from-orange-600/20 to-amber-600/20"
        />

        <FeatureSection
          title="Web Search & Translation"
          description="Access comprehensive information about Jerusalem through integrated web search and real-time translation capabilities in multiple languages."
          image={webSearch}
          gradient="from-indigo-600/20 to-violet-600/20"
        />
      </div>
      <Footer />
    </div>
  );
}

export default Overview;
