import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import SearchBar from '../components/SearchBar';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';
import questionsAnswers from '../assets/images/questions-answers.png';
import documentCreation from '../assets/images/document-creation.png';
import navigationAssistance from '../assets/images/navigation-assistance.png';
import webSearch from '../assets/images/web-search.png';
import languageTranslation from '../assets/images/language-translation.png';
import storyWriting from '../assets/images/story-writing.png';
import writingCode from '../assets/images/writing-code.png';
import FeaturesMobile from '../components/FeaturesMobile';
import MobileNavbar from '../components/MobileNavbar';

function Home() {
  const descriptions = [
    "Unlock the city’s treasures—both ancient and modern.",
    "Craft the perfect culinary journey.",
    "Stay informed with real-time news.",
    "Find authentic markets and boutique shops.",
    "Simplify your life with AI guidance.",
    "Plan your day with up-to-the-minute weather."
  ];

  const features = [
    {
      title: "Interactive Q&A",
      description: "Ask questions and receive instant answers about Jerusalem and beyond.",
      image: questionsAnswers,
      link: "/overview"
    },
    {
      title: "Document Creation",
      description: "Write documents, essays, blogs, and more.",
      image: documentCreation,
      link: "/overview"
    },
    {
      title: "Language Translation",
      description: "Translate text in real-time.",
      image: languageTranslation,
      link: "/overview"
    },
    {
      title: "Web Search Capabilities",
      description: "Perform web searches directly through the app.",
      image: webSearch,
      link: "/overview"
    },
    {
      title: "Story Writing",
      description: "Create stories with AI assistance.",
      image: storyWriting,
      link: "/overview"
    },
    {
      title: "Writing Code",
      description: "Write code with AI assistance.",
      image: writingCode,
      link: "/overview"
    },
    {
      title: "Navigation Assistance",
      description: "Integrates with Google Maps to help navigate the city.",
      image: navigationAssistance,
      link: "/overview"
    },
  ];

  const prompts = [
    'Plan a trip to Jerusalem.',
    'Stay updated with real-time news.',
    'Discover local traditions and customs.',
    'Uncover Jerusalem’s hidden treasures.',
    'Enhance your learning experience.',
    'Connect with local experts.',
    'Prepare engaging class assignments.',
    'Plan educational field trips.',
    'Create interactive learning experiences.',
    'Navigate to the Damascus Gate.',
    'Help me learn coding.',
    'Create a game from scratch.',
    'Develop coding-related skills.',
    'Create a story.'
  ];

  const translations = {
    'Plan a trip to Jerusalem.': [
      'Plan a trip to Jerusalem.',
      'خطط لرحلة إلى القدس.',
      'エルサレムへの旅行を計画する。',
      'תכנן טיול לירושלים.',
      'Planifica un viaje a Jerusalén.',
      'به سفر به اورشلیم برنامه ریزی کنید.'
    ],
    'Stay updated with real-time news.': [
      'Stay updated with real-time news.',
      'ابق على اطلاع بأحدث الأخبار.',
      'リアルタイムのニュースで最新情報を入手してください。',
      'הישאר מעודכן עם חדשות בזמן אמת.',
      'Mantente actualizado con noticias en tiempo real.',
      'با اخبار لحظه‌ای به روز باشید.'
    ],
    'Discover local traditions and customs.': [
      'Discover local traditions and customs.',
      'اكتشف التقاليد والعادات المحلية.',
      '地元の伝統と習慣を発見する。',
      'גלה את המסורות והמנהגים המקומיים.',
      'Descubre las tradiciones y costumbres locales.',
      'آداب و رسوم محلی را کشف کنید.'
    ],
    'Uncover Jerusalem’s hidden treasures.': [
      'Uncover Jerusalem’s hidden treasures.',
      'اكتشف كنوز القدس المخفية.',
      'エルサレムの隠れた宝物を見つける。',
      'גלה את האוצרות הנסתרים של ירושלים.',
      'Descubre los tesoros ocultos de Jerusalén.',
      'گنجینه‌های پنهان اورشلیم را کشف کنید.'
    ],
    'Enhance your learning experience.': [
      'Enhance your learning experience.',
      'عزز تجربتك التعليمية.',
      '学習体験を向上させる。',
      'שפר את חוויית הלמידה שלך.',
      'Mejora tu experiencia de aprendizaje.',
      'تجربه یادگیری خود را ارتقا دهید.'
    ],
    'Connect with local experts.': [
      'Connect with local experts.',
      'تواصل مع الخبراء المحليين.',
      '地元の専門家とつながる。',
      'התחבר ע מומחים מקומיים.',
      'Conéctate con expertos locales.',
      'با کارشناسان محلی ارتباط برقرار کنید.'
    ],
    'Prepare engaging class assignments.': [
      'Prepare engaging class assignments.',
      'أعد مهام دراسية مثيرة.',
      '魅力的なクラス課題を準備する。',
      'הכן משימות כיתה מעניינות.',
      'Prepara tareas de clase atractivas.',
      'وظایف کاسی جذاب آماده کنید.'
    ],
    'Plan educational field trips.': [
      'Plan educational field trips.',
      'خطط لرحلات ميدانية تعليمية.',
      '教育的な遠足を計画する。',
      'תכנן טיולים חינוכיים.',
      'Planifica excursiones educativas.',
      'سفرهای آموزشی برنامه ریزی کنید.'
    ],
    'Create interactive learning experiences.': [
      'Create interactive learning experiences.',
      'أنشئ تجارب تعليمية تفاعلية.',
      'インタラクティブな学習体験を作成する。',
      'צור חוויות למידה אינטראקטיביות.',
      'Crea experiencias de aprendizaje interactivas.',
      'تجارب یادگیری تعاملی ایجاد کنید.'
    ],
    'Navigate to the Damascus Gate.': [
      'Navigate to the Damascus Gate.',
      'توجه إلى باب دمشق.',
      'ダマスカスゲートにナビゲートする。',
      'נווט לעבר שער דמשק.',
      'Navega hacia la Puerta de Damasco.',
      'به سمت دروازه دمشق بروید.'
    ],
    'Help me learn coding.': [
      'Help me learn coding.',
      'ساعدني في تعلم البرمجة.',
      'コーディングを学ぶ手助けをしてください。',
      'עזור לי ללמוד קוד.',
      'Ayúdame a aprender a programar.',
      'به من در یادگیری کدنویسی کمک کنید.'
    ],
    'Create a game from scratch.': [
      'Create a game from scratch.',
      'أنشئ لعبة من الصفر.',
      'ゼロからゲームを作成する。',
      'צור משחק מאפס.',
      'Crea un juego desde cero.',
      'یک بازی از ابتدا بسازید.'
    ],
    'Develop coding-related skills.': [
      'Develop coding-related skills.',
      'طور مهارات متعلقة بالبرمجة.',
      'コーディングに関連するスキルを開発する。',
      'פיתח מיומנויות הקשורות לקוד.',
      'Desarrolla habilidades relacionadas con la programación.',
      'مهارت‌های مرتبط با کدنویسی را وسعه دهید.'
    ],
    'Create a story.': [
      'Create a story.',
      'أنشئ قصة.',
      '物語を作成する。',
      'צור סיפור.',
      'Crea una historia.',
      'یک داستان بسازید.'
    ]
  };

  const [currentPrompt, setCurrentPrompt] = useState(0);
  const [currentTranslation, setCurrentTranslation] = useState(0);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const featureContainerRef = useRef<HTMLDivElement>(null);
  const [activeButton, setActiveButton] = useState('prev');
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTranslation((prevTranslation) => {
        const nextTranslation = (prevTranslation + 1) % translations[prompts[currentPrompt] as keyof typeof translations].length;
        if (nextTranslation === 0) {
          setCurrentPrompt((prevPrompt) => (prevPrompt + 1) % prompts.length);
        }
        return nextTranslation;
      });
    }, 3000);
    return () => clearInterval(interval);
  }, [currentPrompt, prompts, translations]);

  const handleSearchClick = (inputValue: string) => {
    const searchQuery = inputValue || translations[prompts[currentPrompt] as keyof typeof translations][currentTranslation];
    window.location.href = `https://chat.jerusalemai.com?prompt=${encodeURIComponent(searchQuery)}`;
  };

  const handlePrevFeature = () => {
    setCurrentFeatureIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : features.length - 1));
  };

  const handleNextFeature = () => {
    setCurrentFeatureIndex((prevIndex) => (prevIndex < features.length - 1 ? prevIndex + 1 : 0));
  };

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    setIsDragging(true);
    const pageX = 'touches' in e ? e.touches[0].pageX : e.pageX;
    setStartX(pageX - featureContainerRef.current!.offsetLeft);
    setScrollLeft(featureContainerRef.current!.scrollLeft);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleDragMove = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const pageX = 'touches' in e ? e.touches[0].pageX : e.pageX;
    const x = pageX - featureContainerRef.current!.offsetLeft;
    const walk = (x - startX) * 2;
    featureContainerRef.current!.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className="bg-black min-h-screen flex flex-col items-center justify-center">
      {isMobileView ? <MobileNavbar /> : <Navbar />}
      <section className="body-font 
        w-[90%]
        max-w-[1700px]
        min-w-[320px]
        h-auto
        min-h-[400px]
        max-h-[50vh]
        md:min-h-[600px]
        md:max-h-[75vh]
        overflow-visible 
        !rounded-xl 
        border 
        border-solid 
        border-white/15 
        text-gray-600 
        shadow-[rgba(0,0,0,0.25)_0px_30px_60px_0px,_rgba(0,0,0,0.25)_0px_15px_30px_0px,_rgba(0,0,0,0.25)_0px_5px_10px_0px,_rgba(204,215,255,0.15)_0px_0px_100px_0px_inset] 
        backdrop-blur-[25px] 
        py-4
        px-4
        md:px-12
        md:py-10
        mb-12 
        text-center 
        mx-auto 
        flex 
        flex-col 
        justify-center 
        items-center 
        mt-[64px]
        transition-transform duration-250 
        hover:scale-[1.005]">
        <h1 className="text-white text-3xl md:text-5xl mb-4">Ask Anything, Explore Everything</h1>
        <SearchBar
          placeholder={translations[prompts[currentPrompt] as keyof typeof translations][currentTranslation]}
          onClick={handleSearchClick}
        />
      </section>
      <section id="overview" className="relative w-full mt-15">
        {isMobileView ? (
          <div className="px-4">
            <h2 className="text-white text-3xl mb-6">Features</h2>
            <FeaturesMobile features={features} />
          </div>
        ) : (
          <div className="hidden md:block w-full px-4 md:px-10">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-white text-4xl">Features</h2>
              <div className="flex ml-auto">
                <button 
                  onClick={() => {
                    handlePrevFeature();
                    setActiveButton('prev');
                  }}
                  className={`text-white text-4xl px-4 py-2 transition-opacity duration-200 ${
                    activeButton === 'next' ? 'opacity-30' : 'opacity-100'
                  }`}
                >
                  ‹
                </button>
                <button 
                  onClick={() => {
                    handleNextFeature();
                    setActiveButton('next');
                  }}
                  className={`text-white text-4xl px-4 py-2 ml-2 transition-opacity duration-200 ${
                    activeButton === 'prev' ? 'opacity-30' : 'opacity-100'
                  }`}
                >
                  ›
                </button>
              </div>
            </div>
            <div className="flex gap-4 overflow-visible">
              <div 
                className="flex gap-4 cursor-grab active:cursor-grabbing" 
                ref={featureContainerRef}
                onMouseDown={handleDragStart}
                onMouseUp={handleDragEnd}
                onMouseLeave={handleDragEnd}
                onMouseMove={handleDragMove}
                onTouchStart={handleDragStart}
                onTouchEnd={handleDragEnd}
                onTouchMove={handleDragMove}
                style={{ 
                  transform: `translateX(calc(-${currentFeatureIndex * 320}px))`,
                  transition: isDragging ? 'none' : 'transform 0.5s ease-in-out'
                }}
              >
                {features.map((feature, index) => (
                  <div 
                    key={index}
                    className="feature-card flex-shrink-0 w-[280px] sm:w-[320px] aspect-[3/4]"
                    tabIndex={0}
                  >
                    <div className="relative h-full w-full overflow-hidden rounded-lg group">
                      {/* Category Label */}
                      <div className="absolute top-0 left-0 z-10 m-6">
                        <span className="text-white text-sm font-medium px-3 py-1 bg-black/50 backdrop-blur-sm rounded-full">
                          Product
                        </span>
                      </div>

                      {/* Image */}
                      <img 
                        src={feature.image} 
                        alt={feature.title} 
                        className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110 group-focus:scale-110"
                      />

                      {/* Gradient Overlay (static, not on hover) */}
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>

                      {/* Description (Always visible) */}
                      <div className="absolute bottom-0 left-0 right-0 p-6">
                        <p className="text-white text-lg font-medium">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </section>
      <footer className="mt-24 text-white flex flex-col items-center gap-2">
        <p>© 2024 JerusalemAI. All rights reserved.</p>
        <p className="text-white/70 text-sm">
          Powered by{' '}
          <a 
            href="https://leapthelimit.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="hover:text-white transition-colors underline"
          >
            LeapTheLimit
          </a>
        </p>
      </footer>
    </div>
  );
}

export default Home;
