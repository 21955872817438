interface Feature {
  title: string;
  image: string;
  description: string;
}

const FeaturesMobile = ({ features }: { features: Feature[] }) => {
  return (
    <div className="w-full overflow-hidden">
      <div className="flex overflow-x-auto snap-x snap-mandatory no-scrollbar gap-4 pb-4">
        {features.map((feature, index) => (
          <div 
            key={index}
            className="snap-center flex-shrink-0 w-[280px] aspect-[3/4] first:ml-0"
          >
            <div className="relative h-full w-full overflow-hidden rounded-lg shadow-lg">
              {/* Category Label */}
              <div className="absolute top-0 left-0 z-10 m-4">
                <span className="text-white text-sm font-medium px-3 py-1 bg-black/50 backdrop-blur-sm rounded-full">
                  {feature.title}
                </span>
              </div>

              <img 
                src={feature.image} 
                alt={feature.title} 
                className="w-full h-full object-cover transition-transform duration-300 active:scale-105"
                loading="lazy"
              />

              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>

              <div className="absolute bottom-0 left-0 right-0 p-6">
                <p className="text-white text-base font-medium">{feature.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}; 

export default FeaturesMobile;