import React from 'react';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';

const PricingToggle = ({ isYearly, onToggle }: { isYearly: boolean; onToggle: () => void }) => (
  <div className="flex justify-center items-center gap-4 mb-12">
    <span className={`text-lg ${!isYearly ? 'text-white' : 'text-white/70'}`}>Monthly</span>
    <button
      onClick={onToggle}
      className="relative w-16 h-8 rounded-full bg-white/10 border border-white/15 transition-colors"
    >
      <div className={`absolute top-1 w-6 h-6 rounded-full bg-white transition-transform duration-200 
        ${isYearly ? 'translate-x-8' : 'translate-x-1'}`} 
      />
    </button>
    <span className={`text-lg ${isYearly ? 'text-white' : 'text-white/70'}`}>Yearly</span>
  </div>
);

const PricingCard = ({ title, price, yearlyPrice, features, isPopular, isYearly }: {
  title: string;
  price: string;
  yearlyPrice?: string;
  features: string[];
  isPopular?: boolean;
  isYearly: boolean;
}) => (
  <div className={`relative p-8 rounded-xl border ${isPopular ? 'border-white/30' : 'border-white/15'} 
    backdrop-blur-sm bg-black/40 flex flex-col gap-6 w-full md:w-[280px]
    ${isPopular ? 'shadow-[0_0_50px_rgba(255,255,255,0.1)]' : ''}`}>
    {isPopular && (
      <div className="absolute -top-3 left-1/2 -translate-x-1/2">
        <span className="px-3 py-1 bg-white text-black text-sm rounded-full">Popular</span>
      </div>
    )}
    <div>
      <h3 className="text-xl text-white mb-2">{title}</h3>
      <div className="flex items-baseline gap-2">
        <span className="text-3xl font-bold text-white">
          {isYearly && yearlyPrice ? yearlyPrice.split('/')[0] : price}
        </span>
        <span className="text-white/70">/{isYearly ? 'year' : 'month'}</span>
      </div>
      {!isYearly && yearlyPrice && (
        <p className="text-white/70 text-sm mt-1">or {yearlyPrice}</p>
      )}
    </div>
    <ul className="flex flex-col gap-3 flex-1">
      {features.map((feature, index) => (
        <li key={index} className="text-white/70 flex items-center gap-2">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.3334 4L6.00008 11.3333L2.66675 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {feature}
        </li>
      ))}
    </ul>
    <button className={`w-full py-2 rounded-lg border transition-all duration-200
      ${isPopular ? 'bg-white text-black border-white hover:bg-white/90' : 
      'border-white/15 text-white hover:bg-white/10'}`}>
      Get Started
    </button>
  </div>
);

function Pricing() {
  const [isMobileView, setIsMobileView] = React.useState(false);
  const [isYearly, setIsYearly] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="bg-black min-h-screen">
      {isMobileView ? <MobileNavbar /> : <Navbar />}
      
      <div className="max-w-[1240px] mx-auto px-4 pt-[120px] pb-20">
        <h1 className="text-4xl md:text-5xl text-white text-center mb-4">Simple, transparent pricing</h1>
        <p className="text-white/70 text-center mb-12">Choose the plan that's right for you</p>
        
        <PricingToggle isYearly={isYearly} onToggle={() => setIsYearly(!isYearly)} />
        
        <div className="flex flex-col md:flex-row gap-6 justify-center items-stretch">
          <PricingCard
            title="Freemium"
            price="$0"
            features={[
              'Text chat',
              'Up to 1,000 request credits/month',
              'Basic features'
            ]}
            isYearly={isYearly}
          />
          
          <PricingCard
            title="Essential"
            price="$10"
            yearlyPrice="$99.99/year"
            features={[
              'All basic features',
              '10,000 request credits',
              'Chat, search, and document creation'
            ]}
            isYearly={isYearly}
          />
          
          <PricingCard
            title="Advanced"
            price="$20"
            yearlyPrice="$190/year"
            features={[
              'All essential features',
              '30,000 request credits',
              'Avatar interaction',
              'Priority support'
            ]}
            isPopular
            isYearly={isYearly}
          />
          
          <PricingCard
            title="Expert"
            price="$39"
            yearlyPrice="$390/year"
            features={[
              'All advanced features',
              '100,000 request credits',
              'Custom Avatar',
              'Google Maps navigation',
              'Premium support'
            ]}
            isYearly={isYearly}
          />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
