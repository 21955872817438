import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Home from './pages/home';
import Docs from './pages/docs';
import Stories from './pages/stories';
import Pricing from './pages/pricing';
import Overview from './pages/overview';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/docs/:feature" element={<Docs />} />
        <Route path="/stories" element={<Stories />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/overview" element={<Overview />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
