import React, { useState } from 'react';

interface SearchBarProps {
  placeholder: string;
  onClick: (inputValue: string) => void;
}

function SearchBar({ placeholder, onClick }: SearchBarProps) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    onClick(inputValue);
  };

  return (
    <div className="bg-gray-700 bg-opacity-50 rounded-full p-4 mt-4 flex items-center justify-between w-full max-w-[670px]">
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        className="bg-transparent border-none text-white outline-none flex-grow mr-4"
      />
      <button onClick={handleButtonClick} className="text-white text-2xl">
        <svg fill="#ffffff" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)" stroke="#ffffff" className="w-6 h-6">
          <path d="M847.059 113c256.15 0 495.247 113.845 656.075 312.282l-87.868 71.153c-139.257-171.896-346.278-270.494-568.207-270.494-404.781 0-734.118 329.337-734.118 734.118 0 404.781 329.337 734.117 734.118 734.117 221.93 0 428.95-98.597 568.207-270.494l87.868 71.153c-160.828 198.438-399.925 312.283-656.075 312.283C379.934 1807.118 0 1427.184 0 960.058 0 492.935 379.934 113 847.059 113Zm129.476 411.817 79.849 79.963-298.955 298.842h1162.616v112.828H757.43l298.955 298.956-79.85 79.85-435.162-435.163 435.163-435.276Z" fillRule="evenodd"></path>
        </svg>
      </button>
    </div>
  );
}

export default SearchBar;